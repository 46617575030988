import React, { Suspense} from "react"; 
import {Loader} from "../../components";
import '../../assets/frontend.scss';
const FrontLayout = (props) => { 
  return (
    <div>
      {/* <Header/> */}
        <div>
          <Suspense fallback={<Loader/>}>
            {props.children}
          </Suspense>
        </div>
      {/* <Footer/> */}
    </div>
  );
};
export default FrontLayout;
