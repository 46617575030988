import React from 'react';
import {Grow} from "@material-ui/core";

function InputError(props) {
  const {delay = 1000, message} = props;
  return (
    <Grow in={message} style={{transformOrigin: '0 0 0'}}
          timeout={delay}>
      <p> {message} </p>
    </Grow>
  );
}

export default InputError;
