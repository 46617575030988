// bg.js
export default {
  mainHead: "Моля изберете опция",
  salad: "Салата",
  meat: "месо",
  sandwich: "сандвич",
  payBill: "Плати сметката",
  reserveTable: "Резервирайте маса",
  placeOrderOrMenu: "направете поръчка или преглед на менюто",

  seraiBistroTable: "Таблица №",
  addATip: "бихте ли искали да добавите съвет",
  youArePaying: "вие плащате",
  saveUpto10: "Спестете до 10%",
  subTotal: "Междинен сбор",
  splitBill: "Разделен Бил",
  payBill: "Плащам сметка",
  addACart: "Добавяне на количка",
  name: "Име",
  cardNumber: "Номер на карта",
  issueDate: "Дата на издаване",
  cvc: "CVC",
  totalBill: "Обща сума на сметката",
  remainingToBe: "останали за разделяне",
  addSplitters: "Добавете сплитери, за да платите по-късно",
  addMoreParticipants: "Добавяне на още участници",
  addShare: "Добавете своя дял",
  payBillBtn: "Плащам сметка",
  paytotal: "Общо плащане",
  roPayUsers: "Потребителите на RoPay ще бъдат уведомени в приложението",
  andFor: "и за",
  nonRopayUsers:
    "За потребители без Ropay ще бъде генерирана персонализирана връзка",
  yourPayableAmount: "Вашата дължима сума",
  reservationCharges:
    "Зарядните устройства за резервации ще бъдат коригирани с вашата сметка за храна",
  splitersPayAmount:
    "След като други сплитъри платят там разделена сума, тя ще бъде автоматично добавена във вашия RoPay акаунт",
  selectOPTION: "Изберете опция",

  addCard: "Добавете кредитна или дебитна карта",
  defaultCard: "Моята карта по подразбиране",

  yourCard: "Вашата карта",
  cardNumber: "Номер на карта",
  number: "Номер",
  saveCard: "Запазете тази карта за по-бързо плащане следващия път",
  continuebtn: "продължи",
  //payment success page
  paymentSuccess: "Успешно плащане",
  youPaidBill: "Вие сте платили цялата сметка",
  billingDetail: "Данни за фактуриране",
  paymentMode: "Режим на плащане",
  total: "Обща сметка",
  dateTime: "Време за среща",
  share: "Вашият дял",
  totalpaid: "Обща платена сума",
  creditCard: "Кредитна карта",
  otherSplitters: "Други сплитери",
  shareLink: "Споделете връзка за изплащане",

  // Reservation section
  reservationName: "Име на резервацията",
  contactDetail: "Въведете данни за контакт",
  mobileno: "Мобилен номер",
  noOfPersons: "Брой лица",
  selectDate: "Изберете Дата",
  selectTime: "Изберете Време",
  instruction: "инструкции",
  person: "Лица",
  enterinstruct: "Въведете инструкции",
  payNow: "Плати сега",
  rerservationDone: "Резервацията е направена",
  bookingName: "Име на резервацията",
  noOfGuests: "Брой на гостите",
  refund: "Сума за възстановяване",
  Date: "Дата",
  Time: "време",
  complete: "Завършено",
  enter: "Въведете",

  bookingdetail: "Подробности за резервацията",
  guests: "гости",
  refund: "Възстановява се",
  incaseOfQuery: "В случай на запитване, свържете се с нас",
  reservationdone: "Резервацията е направена",
  tablereserved: "Масата е запазена",
  menuSerai: "Меню",

  categories: "категории",
  specialItem: "Специален артикул",
  item: "вещ",
  showAll: "Покажи всички",
  resver: "Таксите за резервация ще бъдат коригирани с вашата сметка за храна",

  //recepies section
  tabboulehSalad: "Салата Табуле / 300 гр.",
  TSaladPrice: "9,99 лв.",
  TSaladServedItems: "с паста орзо, авокадо, магданоз и червен лук",

  pumpkinSalad: "Салата с тиква / 350 гр.",
  pSaladPrice: "12,99 лв.",
  pSaladServedItems: "авокадо, микс салати, нар, фета сирене и пистачио",

  iberianBecon: "Иберийска сланина / 200 гр.",
  ibBeconPrice: "14,99 лв.",
  ibBeconServedItems: "с пресен лук и хлебче на пещ",

  bakedGoat: "Запечено зряло козе сирене / 250 гр.",
  bGoatPrice: "15,99 лв.",
  bGoatServedItems: "със сладко от зелени смокини и орехи",

  vealTongue: "Телешки език в масло / 250 гр.",
  vTonguePrice: "19,99 лв.",
  vTongueItems: "с пушен червен пипер",

  hummus: "Фарфале върху хумус / 300 гр. ",
  hummusPrice: "12,99 лв.",
  hummusItems: "с микс свежи салати",

  orzoPasta: "Паста орзо с чоризо / 350 гр.",
  oPastaPrice: "16,99 лв.",
  oPastaItems: "тиквички, чери домати и бейби спанак",

  linguiniChicken: "Лингуини с пиле / 350 гр.",
  linguiniChickenPrice: "15,99 лв.",
  linguiniChickenItems: "броколи, сушени домати и пармезан",

  liguinTomatoes: "Лингуини с домати / 350 гр.",
  liguinTomatoesPrice: "17,99 лв.",
  liguinTomatoesItems: "страчатела и пистачио",

  chickenChestnuts: "Пиле с кестени / 350 гр.",
  chickenChestnutsPrice: "17,99 лв.",
  chickenChestnutsItems: "приготвено на тиган с картофено пюре е тиква",

  ourGyros: "Нашият гирос / 350 гр.",
  ourGyrosPrice: "16,99 лв.",
  ourGyrosItems:
    "гръцка питка, пилешко месо, домати, майонеза, тирокафтери, пържени картофи",

  neapolitanPizza: "Неаполитанска пица / 300 гр.",
  neapolitanPizzaPrice: "21,99 лв.",
  neapolitanPizzaItems:
    "трюфелов салам, моцарела фиор ди лате, доматен сос и рукола",

  porknoisettes: "Свински ноазети / 350 гр.",
  porknoisettesPrice: "22,99 лв.",
  porknoisettesiItems: "пепър сос, картофено пюре, бейби спанак и манатарки",

  seaBass: "Лаврак филе / 350 гр.",
  seaBassPrice: "29,99 лв.",
  seaBassItems:
    "маслено-лимонов сос, пистачио, паста орзо, сушени домати и броколи",

  salmonFillet: "Сьомга филе с авокадо / 350 гр.",
  salmonFilletPrice: "29,99 лв.",
  salmonFilletItems: "чери домати, маслини таджаска, черен ориз и аспержи",

  bakedPumpkin: "Печена тиква / 200 гр.",
  bakedPumpkinPrice: "7,99 лв.",
  bakedPumpkinItems: "със сладолед и пистачио",

  //Categories Names
  meatItems: "Месни артикули",
  saladItems: "Продукти за салата",
  sandwichItems: "Сандвич артикули",

  begining: "Начало",
  forLunch: "Обяд",
  pizza: "пица",
  mainMenu: "Главно меню",
  alcoholDrinks:"Алкохолни напитки - 50 мл",

  //forLunch Dishes
  SUPI: "С У П И",
  salmonFishSoup: "Рибена супа от сьомга",
  salmonFishSoupPrice: "8.99 лв. / 300 гр.",

  salads: "С А Л А Т И",
  greenSaladWithTuna: "Зелена салата с риба тон",
  greenSaladWithTunaItems:
    "(зелена салата, краставици, пресен лук, риба тон, царевица)",
  greenSaladWithTunaPrice: "8.50 лв. / 300 гр.",

  mainDishes: "О С Н О В Н ИЯ С Т И Я",

  stuffedPepersRice: "Пълнени чушки с мляно месо и ориз",
  stuffedPepersRicePrice: "10.90 лв. / 350 гр.",

  leberkezBread: "Леберкез пане със салата зеле и моркови",
  leberkezBreadPrice: "9.99 лв. / 350 гр.",

  pulledPork: "Дърпан свински врат със зеленчуци на фурна",
  pulledPorkPrice: "10.99 лв. / 350 гр.",

  BakedCottageCheese: "Кашкавал на фурна с г-ра пресни домати",
  BakedCottageCheesePrice: "11.99 лв. / 350 гр.",

  mishMash: "Миш-маш",
  mishMashPrice: "9.99 лв. / 350 гр.",

  retroPizza: "Ретро пица",
  retroPizzaItems: "(дом.сос, моцарела, кис.краставички,кренвирш)  ",
  retroPizzaPrice: "голяма - 14.99 лв. малка - 7.99 лв.  ",

  romanPizza: "Римска пица  ",
  romanPizzaItems: "(доматен сос, бекон, гъби, моцарела)  ",
  romanPizzaPrice: "голяма - 14.99 лв. малка - 7.99 лв.  ",

  biscuit: "Бисквитена торта с бял шоколад и марини  ",
  biscuitPrice: "9.99 лв.  ",

  homemadeLemonade: "Домашна лимонада от ягоди  ",
  homemadeLemonadePrice: "13.99 лв. / 1.000 л. 6.99 лв. / 400 мл.",

  //for Pizza recipes
  diliciousPizza: "най-вкусните ПИЦИ & ХЛЕБЧЕТА",

  perfettoPizza:
    "пиците на ресторант Perfetto могат да бъдат приготвени с традиционно тесто, тесто hand - разточено на ръка, и интегрално (диетично) тесто",
  perfettoPizzaDetail: "може да добавите борд с филаделфия за 5,80 лв.",
  //perfettoPizzaPrice:"BGN 10.99 / 300 g.",

  margarita: "Маргарита",
  margaritaDetail: "доматен сос, кашкавал,риган и маслини",
  margaritaPrice: "10.99 лв. / 300 гр.",

  caprice: "Капричоза",
  capriceDetail: "доматен сос, кашкавал,шунка, гъби, риган",
  caPricePrice: "15,99 лв. / 300 гр.",

  childernPizzaSmall: "Детска пица - малка",
  childernPizzaSmallDetail: "доматен сос, кашкавал,шунка, маслини",
  childernPizzaSmallPrice: "7,99 лв. / 150 гр.",

  childernPizzaLarge: "Детска пица - голяма",
  childernPizzaLargeDetail: "доматен сос, кашкавал,шунка, маслини",
  childernPizzaLargePrice: "14,99 лв. / 300 гр.",

  pepperone: "Пепероне",
  pepperoneDetail: "доматен сос, кашкавал,салам пеперони",
  pepperonePrice: "17,99 лв. / 300 гр.",

  marcoPolo: "Марко Поло",
  marcoPoloDetail:
    "дом.сос, кашкавал, пилешко филе, топено сирене,царевица, к.краставички, риган, маслини",
  marcoPoloPrice: "19,99 лв. / 300 гр.",

  qattroFormaggi: "Куатро формаджи",
  qattroFormaggiDetail:
    "доматен сос, моцарела, синьо сирене,ементал, топено сирене президент",
  qattroFormaggiPrice: "20,99 лв. / 300 гр.",

  perfetto: "Перфетто",
  perfettoDetail: "доматен сос, моцарела,прошуто, чери домати, пекорино",
  perfettoPrice: "21,99 лв. / 300 гр.",

  BreadOregano: "Хлебче със зехтин и риган",
  BreadOreganoPrice: "3,99 лв. / 150 гр.  ",

  garlicBreadPrice: "3,99 лв. / 150 гр.  ",
  garlicBread: "Чесново хлебче  ",

  cheeseBreadPrice: "6,99 лв. / 150 гр.  ",
  cheeseBread: "Хлебче с кашкавал  ",

  wholemealBread: "Хлебче интегрално  ",
  wholemealBreadItems: "garlic sauce  ",
  wholemealBreadPrice: "4,99 лв. / 150 гр.  ",

  garlicSauce: "Чеснов сос",
  garlicSauceItems: "майонеза, чесън, кисело мляко  ",
  garlicSaucePrice: "2,50 лв. / 70 мл.  ",

  italianSauce: "Италиански сос  ",
  italianSauceItems: "майонезен, кетчуп, зехтин, черен пипер  ",
  italianSaucePrice: "2,50 лв. / 70 мл.  ",

  spicy: "Дяволо пикант  ",
  spicyItems: "майонеза, дом.сос, чесън, пеперончино  ",
  spicyPrice: "2,50 лв. / 70 мл.  ",

  //Main Menu
  greenSalad: "Зелена салата с яйце / 300 гр.  ",
  greenSaladPrice: "10,99 лв.  ",
  greenSaladItems:
    "зелена салата, краставици, репички, маслини, яйце, лук пресен  ",

  burataSalad: "Салата Бурата / 350 гр.",
  burataSaladPrice: "23,99 лв.  ",
  burataSaladItems:
    "домати, маслини таджаска, авокадо, бурата, пресен босилек  ",

  tzatzikiSalad: "Салата Дзадзики / 250 гр.  ",
  tzatzikiSaladPrice: "10,99 лв.  ",

  greekSalad: "Гръцка салата / 300 гр.  ",
  greekSaladPrice: "13,99 лв.  ",
  greekSaladItems:
    "домати,краставици, пресни чушки,сирене фета, лук, маслини,риган, зехтин, зелена салата",

  avocadoSalad: "Салата авокадо / 300 гр.  ",
  avocadoSaladPrice: "16,99 лв.  ",
  avocadoSaladItems:
    "айсберг, рукола, краставици,сушени домати, авокадо овкусени с винегрет",

  caesarSalad: "Салата Цезар с пиле / 350 гр.",
  caesarSaladPrice: "14,99 лв.",
  caesarSaladItems: "айсберг,пилешко филе,пармезан,кротони,сос Цезар с аншоа",

  pomegranateSalad: "Салата с нар и халуми / 320 гр.",
  pomegranateSaladPrice: "17,99 лв.",
  pomegranateSaladItems:
    "бейби спанак, нар, моркови, сирене халуми, чери домати,айсберг и винегрет",

  capreseSalad: "Салата Капрезе / 350 гр.",
  capreseSaladPrice: "14,99 лв.",
  capreseSaladItems: "домати, моцарела, зехтин и сос песто",

  homemadeCheeseSalad: "Салата с домашно прясно сирене / 350 гр.",
  homemadeSaladPrice: "16,99 лв.  ",
  homemadeSaladItems:
    "домати, прясно сирене, катък, орехово песто, баба Гануш, печена червена чушки, лук шайби  ",

  crispySalad: "Салата с хрупкави калмари / 300 гр.  ",
  crispySaladPrice: "18,99 лв.",
  crispySaladItems:
    "микс салати, печена тиква, чери домати, пресен лук, овкусени с балсамов винегрет, гарнирани с хрупкави калмари  ",

  goatCheeseSalad: "Салата с козе сирене / 320 гр.  ",
  goatCheeseSaladPrice: "18,99 лв.",
  goatCheeseSaladItems:
    "запечено козе сирене, микс салати овкусени с винегрет, домати, сладко от зелени смокини  ",

  tunaMousse: "Салата Тоно мус / 300 гр.",
  tunaMoussePrice: "18,99 лв.",
  tunaMousseItems:
    "микс салати, чери домати, тоно мус, цитрус дресинг, царевично хлебче  ",

  chickenSoup: "Пилешка супа / 300 мл.  ",
  chickenSoupPrice: "6,99 лв.  ",

  tarator: "Таратор / 300 мл.  ",
  taratorPrice: "4,99 лв.  ",

  avocadoTartar: "Тартар от авокадо с пушена сьомга / 300 гр.",
  avocadoTartarPrice: "23,99 лв.",
  avocadoTartarItems:
    "авокадо, краставица, сос айоли, яйце, червен лук и пушена сьомга",

  vealCarpaccio: "Телешко карпачо / 180 гр.  ",
  vealCarpaccioPrice: "33,99 лв.  ",
  vealCarpaccioItems:
    "Блек ангъс, рукола, пармезан, трюфелова майонеза, зехтин и балсамова редукция",

  beefTartar: "Телешки тартар / 200 гр.",
  beefTartarPrice: "45,99 лв.",
  beefTartarItems:
    "Телешки тартар от “Блек ангъс“ с трюфел, майонеза, червен лук, каперси, горчица, поднесен с лимецов хляб  ",

  marinatedGavros: " Маринован гаврос / 150 гр.",
  marinatedGavrosPrice: "17,99 лв.",
  marinatedGavrosItems: "филе от маринован гаврос с лук и лимон",

  eggplant: "Патладжан / 350 гр.  ",
  eggplantPrice: "8,99 лв.",
  eggplantItems: "по сицилиански със сос тахини, зехтин и магданоз",

  friedZucchini: "Пържени тиквички / 200 гр.",
  friedZucchiniPrice: "11,99 лв.",
  friedZucchiniItems: "поднесени с млечен сос",

  bakedAvocado: "Авокадо запечено / 250 гр. ",
  bakedAvocadoItems: "с козе сирене",
  bakedAvocadoPrice: "22,99 лв.",

  foieGras: "Гъши дроб с карамелизиран ананас / 250 гр.",
  foieGrasPrice: "55,00 лв.",
  foieGrasItems: "поднесени със сос от боровинки",

  chickenSticks: "Пилешки пръчици с / 200rp",
  chickenSticksItems: "корнфлейкс",
  chickenSticksPrice: "15,99 лв.",

  steamedMushrooms: "Задушени манатарки / 180 гр.",
  steamedMushroomsPrice: "25,99 лв.",

  crispySquid: "Хрупкави калмари / 200 гр.",
  crispySquidPrice: "18,99 лв.",

  shrimpsCurry: "Скариди / 160 гр.",
  shrimpsCurryItems: "с кокосово мляко и червено къри ",
  shrimpsCurryPrice: "25,99 лв.",

  tagliatelleBolognese: "Талиателе Болонезе / 400 гр.",
  tagliatelleBolognesePrice: "16,99 лв.",
  tagliatelleBologneseItems: "талиатели, кайма, доматен сос",

  tagliatelleMusrooms: "Талиателе / Ризото с манатарки / 330 гр.",
  tagliatelleMusroomsPrice: "17,99 лв.",
  tagliatelleMusroomsItems: "манатарки, пармезан, сметана",

  tagliatelleFruti: "Талиателе / Ризото Фрути ди Маре / 350 гр.",
  tagliatelleFruti: "26,99 лв.",
  tagliatelleFrutiItems:
    "калмари, октопод, скариди, сушени домати, къри Мадрас, пресен спанак, сметана, пармезан, чесън",

  risottoChicken: "Талиателе / Ризото с пиле и гъби / 350 гр.",
  risottoChickenPrice: "16,99 лв.",
  risottoChickenItems: "пилешко филе, пресни гъби, сметана, пармезан, масло",

  gyozaBeef: "Гьоза с телешко месо / 160 гр..",
  gyozaBeefPrice: "15,99 лв.",
  gyozaBeefItems: "и тай сос",

  beefSteak: "Телешки стек / 100 гр.",
  beefSteakPrice: "35,00 лв.",
  beefSteakItems: "“Блек Ангъс“ от бон филе",

  viennese: "Виенски шницел с домашно пържени картофи / 450 гр.",
  viennesePrice: "22,99 лв.",
  vienneseItems:
    "панирано свинсо бон филе поднесено с пържени картофи и лимон с билково масло",

  vealRoasted: "Теленце печено на бавен огън / 420 гр.",
  vealRoastedPrice: "27,99 лв.",
  vealRoastedItems: "поднесено с домашно картофено пюре с пресен спанак",

  overnRoastedPork: "Свински джолан запечен на пещ / 450 гр.",
  overnRoastedPorkPrice: "19,99 лв.",
  overnRoastedPorkItems:
    "поднесен със сос от горски гъби, гарниран с картофи соте",

  poekCheeks: "Свински бузи на тиган / 350 гр.",
  porkCheeksPrice: "25,99 лв.",
  porkCheeksItems:
    "поднесен със сос от сини сливи, гарнирани със сладак картоф",

  chickenTruffle: "Пиле с трюфел / 350 гр.",
  chickenTrufflePrice: "19,99 лв.",
  chickenTruffleItems: "и филаделфия",

  salmonSaffronSauce: "Сьомга с шафранов сос / 350 гр.",
  salmonSaffronSaucePrice: "39,99 лв.",
  salmonSaffronSauceItems: "върху задушен черен ориз",

  veganMeatballs: "Веган кюфтенца / 300 гр.",
  veganMeatballsPrice: "19,99 лв.",
  veganMeatballsItems: "с хумус в арабски стил",

  lambChops: "Агнешки котлетчета / 400 гр.",
  lambChopsPrice: "75,00 лв.",
  lambChopsItems: "сладки картофи, хумус, свежи салати",

  seaBassFillet:"Лаврак филе / 100 гр.", 
  seaBassFilletPrice:"14,99 лв.",

  salmonFillet1:"Сьомга филе / 100 гр.",
  salmonFilletPrice1:"16,99 лв.",

  friedCod:"Бакаляро пържено / 100 гр.",
  friedCodPrice:"13,99 лв.",

  seaBassKG:"Лаврак 1кг / за 100 гр.",
  seaBassKGPrice:"11,99 лв.",


  meatBallsKebab:"Кюфте / Кебапче / 100 гр.",
  meatBallsKebabPrice:"4,50 лв.",

  beefMeatBall:"Телешко кюфте / 100 гр.",
  beefMeatBallPrice:"5,99 лв.",

porkCutlet:"Свински котлет / 550 гр.",
porkCutletPrice:"25,99 лв.",

chickenBreast:"Пилешки карета / 180 гр.",
chickenBreastPrice:"12,99 лв.",

carettaPork:"Карета от свинско бон филе / 250 гр.",
careettaPorkPrice:"15,99 лв.",

beefSteakWithBonFillet:"Телешки стек от бон филе / 100 гр.",
beefSteakPriceWithBonFilletPrice:"20,99 лв.",

homemadeFriedPotatoes:"Домашно пържени картофи / 200 гр.",
homemadeFriedPotatoesPrice:"6,99 лв.",

sauteedPotatoes:"Картофи соте / 250 гр.",
sauteedPotatoesPrice:"6,99 лв.",

stewedBlackRice:"задушени с копър и чесън Задушен черен ориз / 200 гр.",
stewedBlackRicePrice:"7,99 лв.",

vegetableStew:"Зеленчуково стю / 200 гр.",
vegetableStewPrice:"7,99 лв.",
vegetableStewItem:"пресни сотирани зеленчуци със зехтин, чесън и магданоз Зеленчуци",

grilledVegetables:"на барбекю / 350 гр.",
grilledVegetablesPrice:"11,99 лв.",

lindCake:"Торта Линд / 150 гр.",
lindCakePrice:"9,99 лв.",

chocolateSouffle:"Шоколадово суфле / 150 гр.",
chocolateSoufflePrice:"9,99 лв.",

torateBiscuit:"Бисквитена торат / 150 гр.",
torateBiscuitPrice:"9,99 лв.",
torateBiscuitItems:"с нутела и крем маскарпоне",

staroPramenDark:"СТАРОПРАМЕН ТЪМНО",
staroPramenDarkPrice:"4,50 лв.",
staroPramenDarkItem:"наливна - 300 мл.",

stellaArtois:"СТЕЛА АРТОА",
stellaArtoisPrice:"4,70 лв.",
stellaArtoisItem:"наливна - 330 мл.",

Kamenica:"КАМЕНИЦА",
KamenicaPrice:"3,50 лв.",
kamenicaItem:"наливна - 330 мл.",

stellaArtois1:"СТЕЛА АРТОА",
stellaArtois1Price:"5,50 лв.",
stellaArtois1Item:"бутилка - 330 мл.",

bex:"БЕКС",
bexPrice:"4,99 лв.",
bexItem:"бутилка - 330 мл.",

kamenica1:"КАМЕНИЦА",
kamenica1Price:"3,99 лв.",
kamenica1tem:"бутилка - 330 мл.",

lefe:"ЛЕФЕ",
lefePrice:"7,50 лв.",
lefeItem:"бутилка - 330 мл.",

hoogarden:"ХУГАРДЕН",
hoogardenPrice:"7,70 лв.",
hoogardenItem:"бутилка - 330 мл.",

guinness:"ГИНЕС",
guinnessPrice:"7,50 лв.",
guinnessItem:"бутилка - 330 мл.",

corna:"КОРОНА",
cornaPrice:"7,50 лв.",
cornaItem:"бутилка - 355 мл.",

franciScanner:"ФРАНЗИСКАНЕР",
franciScanner:"9,50 лв.",
franciScannerItem:"бутилка",

// Alcohol Drinks

glenfiddichSingle12Yo:"Гленфидих Сингл Малц 12г.",
glenfiddichSingle12YoPrice:"15.99 лв.",

glenfiddichSingle15Yo:"Гленфидих Сингьл Малц 15г.",
glenfiddichSingle15YoPrice:"21.99 Лв.",

glenfiddichSingle18Yo:"Гленфидих Сингьл Малц 18г.",
glenfiddichSingle18YoPrice:"37.99 лв.",

glenfiddichGran21Yo:"Гленфидих Гран Резерва Сингл Малц 21г.",
glenfiddichGran21YoPrice:"113.00 лв.",

balvenieDoublewood12Yo:"Балвени 12г.",
balvenieDoublewood12YoPrice:"32.00 лв.",

caribbean:"Балвени 14г.",
caribbeanPrice:"38.00 лв.",

balveniePortwoord:"Балвени 21г.",
balveniePortwoordPrice:"128.00 AB.",

monkeyShoulder:"Мьнки Шолдьр",
monkeyShoulderPrice:"11.99 Ав.",

graint12Yo:"ГРАНТС 12г.",
graint12YoPrice:"11.00 лв.",

grants:"Уиски Грантс Трипъл Ууд",
grants:"6.99 AB.",

chivasRegal:"Чивас Регал 12г.",
chivalRegalPrice:"12.99 Ав.",

tullmore:"Тьламор Дю",
tullmorePrice:"8.20 лв.",

tullmore12Yo:"Джеймисьн",
tullmore12YoPrice:"8.20 лв.",

jameson:"Тьламор Дю 12г.",
jamesonPrice:"16.00 лв.",

belugaNoble:"Белуга Нобъл",
belugaNoblePrice:"17.99 лв.",

belugaTranslanticRacing:"Белуга Трансатлантик",
belugaTranslanticRacingPrice:"25.90 лв.",

belugaGoldLine:"Белуга Голд Лайн",
belugaGoldLinePrice:"58.00 лв.",

reyka:"Рейка",
reykaPrice:"10.90 лв.",

absolut:"Абсолют",
AbsolutPrice:"7.90 лв.",

sailorJerry:"Сейльр Джери",
sailorJerryPrice:"9.90 лв.",

angostura7YO:"Ангостура 7г.",
angostura7YOPrice:"9.90 лв.",


//pay the Bill
avocadoSaladBillPage:"2x салата с авокадо",
avocadoSaladBillPagePrice:"22.00 лв",

name :"Име",
cardNumber:"Номер на карта",
issueDate:"Дата на издаване",
cvc:"CVC",

BGN:"лв",

classicLemonadeSuccessPage:"2x Класическа лимонада",
gavurdagiSaladSuccessPage:"1x Салата Гавурдаги",
tipSuccessPage:"Бакшиш",
saveUpTo:"Спестете до 10%",
viewRecipts:"Вижте всичките си рецепти на едно място",
totalYourBill:"Общо (Вашата сметка)",
paymentSuccess1:"Успешно плащане!",
paidFull:"Платихте изцяло",
thankPaying:"Благодарим ви за плащането",
vatInclude:"ДДС включва",
orderPlaced:"Поръчката е направена",
selectYouWantToPay:"Изберете кога искате да платите",
placeOrder:"Направи поръчка",
mins:"мин",

//dine view bill
customerSupport:"В случай на проблем или запитване, свържете се с нашата поддръжка за клиенти",
hours:"часа",
minutes:"минути",
seconds:"секунди",
yourOrderReady:"Вашата поръчка се подготвя!",
estimatedServingTime:"Очакваното време за сервиране е",
remainingTime:"Оставащо време",

//my reservation
rerservationRemainTime:"Вашата резервация остава време",

splitterDetail:"Подробности за сплитер",
splitBillDetail:"ДЕТАЙЛИ ЗА РАЗДЕЛЕНА СМЕТКА",
infiniteCreditCard:"Кредитна карта FAB Infinite",
yourCard:"вашата карта",
selectOrderMethod:"изберете Метод на поръчка",

};
