// LanguageContext.js
import { createContext, useContext, useState ,useEffect} from "react";
import en from '../../assets/lang/en';
import bg from '../../assets/lang/bn';

const LanguageContext = createContext();
export const LanguageProvider = ({ children }) => {

    const storedLanguage = localStorage.getItem("language");
    const [language, setLanguage] = useState(storedLanguage || "en");
    const translations = language === "en" ? en : bg;


    const switchLanguage = (newLanguage) => {
        setLanguage(newLanguage);
    //    localStorage.setItem("language", newLanguage);
    };

    useEffect(() => {
        localStorage.setItem("language", language);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, switchLanguage,translations }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};
