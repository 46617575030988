import React from 'react';
import {useStyles} from "./../../container/frontend/home/styles";

function Index(props) {
	const classes = useStyles();
	const {text}=props;
	return (
		<div className={classes.nothingToShowContainer}>
			<div className='nothing-to-show-text'>{text}</div>
		</div>
	);
}
export default Index;