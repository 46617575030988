import BackgroundHome from './BackgroundHome.png';
import SeraiImage from './SeraiImage.svg';
import checkMark from './checkMark.png';
import NewBackgroundHome from './NewBackgroundHome.png';
import NewSeraiImage from './NewSeraiImage.svg';
import AccountImage from './accountImage.png';
import AccountImage2 from './accountImage2.png';
import AlviByTashas from './AlviByTashas.png';
import SeraiBistro from './SeraiBistro.png';
import PaymentSuccessDp from './PaymentSuccessDp.png'
import seraiLgo from './SeraiLgo.png';
import delivery from './delivery.png';
export {
    BackgroundHome,
    SeraiImage,
    checkMark,
    NewBackgroundHome,
    NewSeraiImage,
    AccountImage,
    AccountImage2,
    AlviByTashas,
    SeraiBistro,
    PaymentSuccessDp,
    delivery,
    seraiLgo
}
