import { alpha, makeStyles } from '@material-ui/core/styles';
import { NewBackgroundHome } from '../../../assets/images/newImages';

export const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url("${NewBackgroundHome}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }
}));    
