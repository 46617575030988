// en.js
export default {
  mainHead: "Please Select an option",
  salad: "Salad",
  meat: "meat",
  sandwich: "sandwich",
  payBill: "Pay Bill",
  reserveTable: "Reserve Table",
  placeOrderOrMenu: "Place Order or View Menu",
  seraiBistroTable: "Table No",
  addATip: "would you like to add a tip",
  youArePaying: "you are paying",
  saveUpto10: "Save upto 10%",
  subTotal: "Sub Total",
  splitBill: "Split Bill",
  payBill: "Pay Bill",
  addACart: "Add a Cart",
  name: "Name",
  cardNumber: "Card Number",
  issueDate: "Issue Date",
  cvc: "CVC",
  totalBill: "Total Bill Amount",
  remainingToBe: "remaining to be split",
  addSplitters: "Add Splitters to Pay Later",
  addMoreParticipants: "Add More Participants",
  addShare: "Add your Share",
  payBillBtn: "Pay Bill",
  paytotal: "Pay Total",
  roPayUsers: "RoPay Users will be notified within app",
  andFor: "and for",
  nonRopayUsers: "Non Ropay Users a Custom link will be Generated",
  yourPayableAmount: "Your Payable Amount",
  reservationCharges:
    "Reservation Chargers will be adjusted with your food bill",
  splitersPayAmount:
    "Once Other Splitters Pay There Split Amount it will be Automatically Added In Your RoPay Account",
  selectOPTION: "Select an Option",

  addCard: "Add Credit or Debit Card",
  defaultCard: "My Default Card",

  yourCard: "Your Card",
  cardNumber: "Card Number",
  number: "Number",
  saveCard: "Save This Card For Faster Checkout next Time ",
  continuebtn: "Continue",
  //payment success page
  paymentSuccess: "Payment Success",
  youPaidBill: "You Paid The Complete Bill",
  billingDetail: "Billing Detail",
  paymentMode: "Payment Mode",
  total: "Total Bill",
  dateTime: "Date & Time",
  share: "Your Share",
  totalpaid: "Total Paid Amount",
  creditCard: "Credit Card",
  otherSplitters: "Other Splitters",
  shareLink: "Share Repay Link",

  // Reservation section
  reservationName: "Reservation Name",
  contactDetail: "Enter Contact Details",
  mobileno: "Mobile Number",
  noOfPersons: "Number of Persons",
  selectDate: "Select Date",
  selectTime: "Select Time",
  instruction: "Instructions",
  person: "Persons",
  enterinstruct: "Enter Instructions",
  payNow: "Pay Now",
  rerservationDone: "Rerservation Done",
  bookingName: "Booking Name",
  noOfGuests: "Number of Guests",
  refund: "Refundable Amount",
  Date: "Date",
  Time: "Time",
  complete: "Complete",
  enter: "Enter",

  bookingdetail: "Booking Detail",
  guests: "Guests",
  refund: "Refundable",
  incaseOfQuery: "Incase of any Query Contact Us",
  reservationdone: "Reservation Done",
  tablereserved: "Table Reserved",
  menuSerai: "Menu",

  categories: "Categories",
  specialItem: "Special Item",
  item: "item",
  showAll: "Show All",
  resver: "Reservation Charges will be adjusted with your food bill",

  ////recepies section/////

  tabboulehSalad: "Tabbouleh salad / 300 g.",
  TSaladPrice: " 9.99",
  TSaladServedItems: "with orzo pasta, avocado, parsley and red onion",

  pumpkinSalad: "Salad with pumpkin / 350 g.",
  pSaladPrice: " 12.99",
  pSaladServedItems:
    "avocado, mixed salads, pomegranate, feta cheese and pistachios",

  iberianBecon: "Iberian bacon / 200 g.",
  ibBeconPrice: " 14.99",
  ibBeconServedItems: "with fresh onions and baked bread",

  bakedGoat: "Baked mature goat cheese / 250 g.",
  bGoatPrice: " 15.99",
  bGoatServedItems: "with jam of green figs and walnuts",

  vealTongue: "Veal tongue in butter / 250 g.",
  vTonguePrice: " 19.99",
  vTongueItems: "with smoked paprika",

  hummus: "Farfalle on hummus / 300 g.",
  hummusPrice: " 12.99",
  hummusItems: "with a mix of fresh salads",

  orzoPasta: "Orzo pasta with chorizo / 350 g.",
  oPastaPrice: " 16.99",
  oPastaItems: "zucchini, cherry tomatoes and baby spinach",

  linguiniChicken: "Linguini with chicken / 350 g.",
  linguiniChickenPrice: " 15.99",
  linguiniChickenItems: "broccoli, sun-dried tomatoes and parmesan",

  liguinTomatoes: "Linguini with tomatoes / 350 g.",
  liguinTomatoesPrice: " 17.99",
  liguinTomatoesItems: "straccatella and pistachios",

  chickenChestnuts: "Chicken with chestnuts / 350 g.",
  chickenChestnutsPrice: " 17.99",
  chickenChestnutsItems: "cooked in a pan with mashed potatoes is pumpkin",

  ourGyros: "Our gyros / 350 g.",
  ourGyrosPrice: " 16.99",
  ourGyrosItems:
    "Greek bread, chicken meat, tomatoes, mayonnaise, thyrokafteri, french fries",

  neapolitanPizza: "Neapolitan pizza / 300 g.",
  neapolitanPizzaPrice: " 21.99",
  neapolitanPizzaItems:
    "truffle salami, mozzarella fior di latte, tomato sauce and arugula",

  porknoisettes: "Pork noisettes / 350 g.",
  porknoisettesPrice: " 22.99",
  porknoisettesiItems:
    "pepper sauce, mashed potatoes, baby spinach and mushrooms",

  seaBass: "Sea bass fillet / 350 g.",
  seaBassPrice: " 29.99",
  seaBassItems:
    "butter-lemon sauce, pistachios, orzo pasta, sun-dried tomatoes and broccoli",

  salmonFillet: "Salmon fillet with avocado / 350 g.",
  salmonFilletPrice: " 29.99",
  salmonFilletItems:
    "cherry tomatoes, tajaska olives, black rice and asparagus",

  bakedPumpkin: "Baked pumpkin / 200 g.",
  bakedPumpkinPrice: " 7.99",
  bakedPumpkinItems: "with ice cream and pistachios",

  //Categories Names
  meatItems: "Meat Items",
  saladItems: "Salad Items",
  sandwichItems: "Sandwich Items",

  begining: "Begining",
  forLunch: "Lunch",
  pizza: "Pizza",
  mainMenu: "Main Menu",
  alcoholDrinks:"Alcohol Drinks - 50 mL",
  //forLunch Dishes
  SUPI: "S UP I",
  salmonFishSoup: "Salmon fish soup",
  salmonFishSoupPrice: " 8.99 / 300 g.",

  salads: "SALADS",
  greenSaladWithTuna: "Green salad with tuna",
  greenSaladWithTunaItems: "(lettuce, cucumbers, spring onions, tuna, corn)",
  greenSaladWithTunaPrice: " 8.50 / 300 g.",

  mainDishes: "Main Dishes",

  stuffedPepersRice: "Stuffed peppers with minced meat and rice",
  stuffedPepersRicePrice: " 10.90 / 350 g.",

  leberkezBread: "Leberkez bread with cabbage and carrot salad",
  leberkezBreadPrice: " 9.99 / 350 g.",

  pulledPork: "Pulled pork neck with vegetables in the oven",
  pulledPorkPrice: " 10.99 / 350 g.",

  BakedCottageCheese: "Baked cottage cheese with Mr. fresh tomatoes",
  BakedCottageCheesePrice: " 11.99 / 350 g.",

  mishMash: "Mish-mash",
  mishMashPrice: " 9.99 / 350 g.",

  retroPizza: "Retro pizza",
  retroPizzaItems: "(homemade sauce, mozzarella, pickled cucumbers, hot dog)",
  retroPizzaPrice: "large -  14.99 , small -  7.99",

  romanPizza: "Roman pizza",
  romanPizzaItems: "(tomato sauce, bacon, mushrooms, mozzarella)",
  romanPizzaPrice: "large -  14.99 ,small -  7.99",

  biscuit: "  Biscuit cake with white chocolate and marinara",
  biscuitPrice: " 9.99",

  homemadeLemonade: "Homemade strawberry lemonade",
  homemadeLemonadePrice: " 13.99 / 1,000 l , 6.99 / 400 ml.",

  //for Pizza recipes
  diliciousPizza: "the most delicious PIZZAS & BREADS",

  perfettoPizza:
    "Perfetto restaurant pizzas can be made with traditional dough hand dough - rolled by hand, and integral (diet) dough ",
  perfettoPizzaDetail: "you can add a board with Philadelphia for  5.80.",
  //perfettoPizzaPrice:' 10.99 / 300 g.',

  margarita: "Margarita",
  margaritaDetail: "tomato sauce, cheese, oregano and olives",
  margaritaPrice: " 10.99 / 300 g.",

  caprice: "Caprice",
  capriceDetail: "tomato sauce, cheese,ham, mushrooms, oregano",
  caPricePrice: " 15.99 / 300 g.",

  childernPizzaSmall: "Childrens pizza small",
  childernPizzaSmallDetail: "tomato sauce, cheese,ham, olives",
  childernPizzaSmallPrice: " 7.99 / 150 g.",

  childernPizzaLarge: "Childrens pizza large",
  childernPizzaLargeDetail: "tomato sauce, cheese,ham, olives ",
  childernPizzaLargePrice: " 14.99 / 300 g.",

  pepperone: "Pepperone",
  pepperoneDetail: "tomato sauce, cheese,pepperoni salami",
  pepperonePrice: " 17.99 / 300 g.",

  marcoPolo: "Marco Polo",
  marcoPoloDetail:
    "homemade sauce, cheese, chicken fillet, processed cheese, corn, cucumbers, oregano, olives",
  marcoPoloPrice: " 19.99 / 300 g.",

  qattroFormaggi: "Quattro formaggi",
  qattroFormaggiDetail:
    "tomato sauce, mozzarella, blue cheese,emmental, melted president cheese",
  qattroFormaggiPrice: " 20.99 / 300 g.",

  perfetto: "Perfetto",
  perfettoDetail:
    "tomato sauce, mozzarella,prosciutto, cherry tomatoes, pecorino",
  perfettoPrice: " 21.99 / 300 g.",

  BreadOregano: "Bread with olive oil and oregano",
  BreadOreganoPrice: " 3.99 / 150 g.",

  garlicBreadPrice: " 3.99 / 150 g.",
  garlicBread: "Garlic bread",

  cheeseBreadPrice: " 6.99 / 150 g.",
  cheeseBread: "Bread with cheese",

  wholemealBread: "Wholemeal bread",
  wholemealBreadItems: "garlic sauce",
  wholemealBreadPrice: " 4.99 / 150 g.",

  garlicSauce: "Garlic sauce",
  garlicSauceItems: "mayonnaise, garlic, yogurt",
  garlicSaucePrice: " 2.50 / 70 ml.",

  italianSauce: "Italian sauce",
  italianSauceItems: "mayonnaise, ketchup, olive oil, black pepper",
  italianSaucePrice: " 2.50 / 70 ml.",

  spicy: "Spicy as hell",
  spicyItems: "mayonnaise, homemade sauce, garlic, pepperoncino",
  spicyPrice: "   2.50 / 70 ml.",

  //Main Menu
  greenSalad: "Green salad with egg / 300 g.",
  greenSaladPrice: " 10.99",
  greenSaladItems:
    "green salad, cucumbers, radishes, olives, egg, spring onions",

  burataSalad: "Burata salad / 350 g.",
  burataSaladPrice: " 23.99",
  burataSaladItems: "tomatoes, tajaska olives, avocado, burrata, fresh basil",

  tzatzikiSalad: "Tzatziki salad / 250 g.",
  tzatzikiSaladPrice: " 10.99",

  greekSalad: "Greek salad / 300 g.",
  greekSaladPrice: " 13.99",
  greekSaladItems:
    "tomatoes, cucumbers, fresh peppers,feta cheese, onions, olives,oregano, olive oil, green salad",

  avocadoSalad: "Avocado salad / 300 g.",
  avocadoSaladPrice: " 16.99",
  avocadoSaladItems:
    "iceberg, arugula, cucumbers,dried tomatoes, avocadoflavored with vinaigrette",

  caesarSalad: "Caesar salad with chicken / 350 g.",
  caesarSaladPrice: " 14.99",
  caesarSaladItems:
    "iceberg, chicken fillet, parmesan, crotons, Caesar sauce with anchovies",

  pomegranateSalad: "Salad with pomegranate and halloumi / 320 g.",
  pomegranateSaladPrice: " 17.99",
  pomegranateSaladItems:
    "baby spinach, pomegranate, carrots, halloumi cheese, cherry tomatoes, iceberg and vinaigrette",

  capreseSalad: "  Caprese salad / 350 g.",
  capreseSaladPrice: " 14.99",
  capreseSaladItems: "  tomatoes, mozzarella, olive oil and pesto sauce",

  homemadeCheeseSalad: "Salad with homemade fresh cheese / 350 g.",
  homemadeSaladPrice: " 16.99",
  homemadeSaladItems:
    "tomatoes, fresh cheese, katak, walnut pesto, baba ganoush, roasted red peppers, onion rings",

  crispySalad: "  Salad with crispysquid / 300 g.",
  crispySaladPrice: " 18.99",
  crispySaladItems:
    "mixed salads, roasted pumpkin, cherry tomatoes, fresh onions, seasoned with balsamic vinaigrette, topped with crispy squid",

  goatCheeseSalad: "Salad with goat cheese / 320 g.",
  goatCheeseSaladPrice: " 18.99",
  goatCheeseSaladItems:
    "baked goat cheese, mixed salads flavored with vinaigrette, tomatoes, green fig jam",

  tunaMousse: "Tuna mousse salad / 300 g.",
  tunaMoussePrice: " 18.99",
  tunaMousseItems:
    "mixed salads, cherry tomatoes, tuna mousse, citrus dressing, corn bread",

  chickenSoup: "Chicken soup / 300 ml.",
  chickenSoupPrice: " 6.99",

  tarator: "Tarator / 300 ml.",
  taratorPrice: " 4.99",

  avocadoTartar: "Avocado tartar with smoked salmon / 300 g.",
  avocadoTartarPrice: " 23.99",
  avocadoTartarItems:
    "avocado, cucumber, aioli sauce, egg, red onion and smoked salmon",

  vealCarpaccio: "Veal carpaccio / 180 g.",
  vealCarpaccioPrice: " 33.99",
  vealCarpaccioItems:
    "Black Angus, arugula, parmesan, truffle mayonnaise, olive oil and balsamic reduction",

  beefTartar: "Beef tartar / 200 g.",
  beefTartarPrice: " 45.99",
  beefTartarItems:
    "Black Angus beef tartare with truffle, mayonnaise, red onion, capers, mustard, served with lime bread",

  marinatedGavros: " Marinated gavros / 150 g.",
  marinatedGavrosPrice: " 17.99",
  marinatedGavrosItems: "fillet of marinated gavros with onion and lemon",

  eggplant: "Eggplant / 350 g.",
  eggplantPrice: " 8.99",
  eggplantItems: "Sicilian style with tahini sauce, olive oil and parsley",

  friedZucchini: "Fried zucchini / 200 g.",
  friedZucchiniPrice: " 11.99",
  friedZucchiniItems: "served with milk sauce",

  bakedAvocado: "Baked avocado  / 250 g.",
  bakedAvocadoItems:"with goat cheese",
  bakedAvocadoPrice: " 22.99",

  foieGras: "Foie gras with caramelized pineapple / 250 g.",
  foieGrasPrice: " 55.00",
  foieGrasItems: "served with blueberry sauce",

  chickenSticks: "Chicken sticks / 200 g.",
  chickenSticksItems:"with CornFlakes",
  chickenSticksPrice: " 15.99",

  steamedMushrooms: "Steamed mushrooms / 180 g.",
  steamedMushroomsPrice: " 25.99",

  crispySquid: "Crispy squid / 200 g.",
  crispySquidPrice: " 18.99",

  shrimpsCurry: "Shrimps  / 160 g.",
  shrimpsCurryItems:"with coconut milk and red curry",
  shrimpsCurryPrice: " 25.99",

  tagliatelleBolognese: "Tagliatelle Bolognese / 400 g.",
  tagliatelleBolognesePrice: " 16.99",
  tagliatelleBologneseItems: "tagliatelle, minced meat, tomato sauce",

  tagliatelleMusrooms: "Tagliatelle / Risotto with mushrooms / 330 g.",
  tagliatelleMusroomsPrice: " 17.99",
  tagliatelleMusroomsItems: "mushrooms, parmesan, cream",

  tagliatelleFruti: "Tagliatelle / Risotto Fruti di Mare / 350 g.",
  tagliatelleFrutiPrice: " 26.99",
  tagliatelleFrutiItems:
    "squid, octopus, shrimp, dried tomatoes, Madras curry, fresh spinach, cream, parmesan, garlic",

  risottoChicken: "Tagliatelle / Risotto with chicken and mushrooms / 350 g.",
  risottoChickenPrice: " 16.99",
  risottoChickenItems:
    "chicken fillet, fresh mushrooms, cream, parmesan, butter",

  gyozaBeef: "Gyoza with beef / 160 g.",
  gyozaBeefPrice: " 15.99",
  gyozaBeefItems: "and Thai sauce",

  beefSteak: "Beef steak / 100 g.",
  beefSteakPrice: " 35.00",
  beefSteakItems: "Black Angus from bon fillet",

  viennese: "Viennese schnitzel with home fries / 450 g.",
  viennesePrice: " 22.99",
  vienneseItems:
    "breaded pork tenderloin served with fries and lemon with herb butter",

  vealRoasted: "Veal roasted on slow fire / 420 g.",
  vealRoastedPrice: " 27.99",
  vealRoastedItems: "served with homemade mashed potatoes with fresh spinach",

  overnRoastedPork: "Oven-roasted pork knuckle / 450 g.",
  overnRoastedPorkPrice: " 19.99",
  overnRoastedPorkItems:
    "served with wild mushroom sauce, topped with sauteed potatoes",

  poekCheeks: "Pork cheeks in a pan / 350 g.",
  porkCheeksPrice: " 25.99",
  porkCheeksItems: "served with prune sauce, topped with sweet potato",

  chickenTruffle: "Chicken with truffle / 350 g.",
  chickenTrufflePrice: " 19.99",
  chickenTruffleItems: "and Philadelphia",

  salmonSaffronSauce: "Salmon with saffron sauce / 350 g.",
  salmonSaffronSaucePrice: " 39.99",
  salmonSaffronSauceItems: "over steamed black rice",

  veganMeatballs:"Vegan meatballs / 300 g.",
  veganMeatballsPrice:" 19.99",
  veganMeatballsItems:"with Arabic-style hummus",

  lambChops:"Lamb chops / 400 g.",
  lambChopsPrice:" 75.00",
  lambChopsItems:"sweet potatoes, hummus, fresh salads",

  seaBassFillet:"Sea bass fillet / 100 g.", 
  seaBassFilletPrice:" 14.99",

  salmonFillet1:"Salmon fillet / 100 g.",
  salmonFillet1Price:" 16.99",

  friedCod:"Fried cod / 100 g.",
  friedCodPrice:" 13.99",

  seaBassKG:"Sea bass 1 kg / for 100 g.",
  seaBassKGPrice:" 11.99",


  meatBallsKebab:"Meatballs / Kebab / 100 g.",
  meatBallsKebabPrice:" 4.50",

  beefMeatBall:"Beef meatball / 100 g.",
  beefMeatBallPrice:" 5.99",

porkCutlet:"Pork cutlet / 550 g.",
porkCutletPrice:" 25.99",

chickenBreast:"Chicken breast / 180 g.",
chickenBreastPrice:" 12.99",

carettaPork:"Caretta of pork bon fillet / 250 g.",
careettaPorkPrice:" 15.99",

beefSteakWithBonFillet:"Beef steak from bon fillet / 100 g.",
beefSteakPriceWithBonFilletPrice:" 20.99",

homemadeFriedPotatoes:"Homemade fried potatoes / 200 g.",
homemadeFriedPotatoesPrice:" 6.99",

sauteedPotatoes:"Sautéed potatoes / 250 g.",
sauteedPotatoesPrice:" 6.99",

stewedBlackRice:"stewed with fennel and garlic Steamed black rice / 200 g.",
stewedBlackRicePrice:" 7.99",

vegetableStew:"Vegetable stew / 200 g.",
vegetableStewPrice:" 7.99",
vegetableStewItem:"fresh sautéed vegetables with olive oil, garlic and parsley",

grilledVegetables:"Grilled vegetables / 350 g.",
grilledVegetablesPrice:" 11.99",

lindCake:"Lind cake / 150 g.",
lindCakePrice:" 9.99",

chocolateSouffle:"Chocolate soufflé / 150 g.",
chocolateSoufflePrice:" 9.99",

torateBiscuit:"Torate biscuit / 150 g.",
torateBiscuitPrice:" 9.99",
torateBiscuitItems:"with nutella and mascarpone cream",

staroPramenDark:"STAROPRAMEN DARK",
staroPramenDarkPrice:" 4.50",
staroPramenDarkItem:"draft - 300 ml.",

stellaArtois:"STELLA ARTOIS",
stellaArtoisPrice:" 4.70",
stellaArtoisItem:"draft - 330 ml.",

Kamenica:"KAMENICA",
KamenicaPrice:" 3.50",
kamenicaItem:"draft - 330 ml.",

stellaArtois1:"STELLA ARTOIS",
stellaArtois1Price:" 5.50",
stellaArtois1Item:"bottle - 330 ml.",

bex:"Bex",
bexPrice:" 4.99",
bexItem:"bottle - 330 ml.",

kamenica1:"KAMENICA",
kamenica1Price:" 3.99",
kamenica1tem:"bottle - 330 ml.",

lefe:"LEFE",
lefePrice:" 7.50",
lefeItem:"bottle - 330 ml.",

hoogarden:"HOOGARDEN",
hoogardenPrice:" 7.70",
hoogardenItem:"bottle - 330 ml.",

guinness:"GUINNESS",
guinnessPrice:" 7.50",
guinnessItem:"bottle - 330 ml.",

corna:"CORONA",
cornaPrice:" 7.50",
cornaItem:"bottle - 355 ml.",

franciScanner:"FRANCISCANNER",
franciScannerPrice:" 9.50",
franciScannerItem:"bottle",

// Alcohol Drinks

glenfiddichSingle12Yo:"Glenfiddich Single Malt 12YO",
glenfiddichSingle12YoPrice:"15.99 v.",

glenfiddichSingle15Yo:"Glenfiddich Single Malt 15YO",
glenfiddichSingle15YoPrice:"21.99 Iv.",

glenfiddichSingle18Yo:"Glenfiddich Single Malt 18YO",
glenfiddichSingle18YoPrice:"37.99 lv.",

glenfiddichGran21Yo:"Glenfiddich Gran Reserva Single Malt 21YO",
glenfiddichGran21YoPrice:"113.00 lv.",

balvenieDoublewood12Yo:"The Balvenie DoubleWood 12YO",
balvenieDoublewood12YoPrice:"32.00 lv.",

caribbean:"The Balvenie Caribbean Cask 14YO",
caribbeanPrice:"38.00 lv.",

balveniePortwoord:"The Balvenie PortWood 21 YO",
balveniePortwoordPrice:"128.00 lv.",

monkeyShoulder:"Monkey Shoulder",
monkeyShoulderPrice:"11.99 Iv.",

graint12Yo:"Grant's 12YO",
graint12YoPrice:"11.00 lv.",

grants:"Grant's Triple Wood",
grantsPrice:"6.99 lv.",

chivasRegal:"Chivas Regal 12YO",
chivalRegalPrice:"12.99 Iv.",

tullmore:"Tullamore D.E.W.",
tullmorePrice:"8.20 lv.",

tullmore12Yo:"Tullamore D.E.W. 12YO",
tullmore12YoPrice:"16.00 lv.",

jameson:"Jameson",
jamesonPrice:"8.20 lv.",

belugaNoble:"Beluga Noble",
belugaNoblePrice:"17.99 Iv.",

belugaTranslanticRacing:"Beluga Transatlantic Racing",
belugaTranslanticRacingPrice:"25.90 lv.",
  
belugaGoldLine:"Beluga Gold Line",
belugaGoldLinePrice:"58.00 lv.",

reyka:"Reyka",
reykaPrice:"10.90 lv.",

absolut:"Absolut",
AbsolutPrice:"7.90 lv.",

sailorJerry:"Sailor Jerry",
sailorJerryPrice:"9.90 lv.",

angostura7YO:"Angostura 7YO",
angostura7YOPrice:"9.90 lv.",

//pay the Bill
avocadoSaladBillPage:"2x Avocado Salad",
avocadoSaladBillPagePrice:"LEV 22.00",

name :"Name",
cardNumber:"Card Number",
issueDate:"Issue Date",
cvc:"CVC",

BGN:"LEV",

//PayBill Page
classicLemonadeSuccessPage:"2x Classic Lemonade",
gavurdagiSaladSuccessPage:"1x Gavurdagi Salad",
tipSuccessPage:"Tip",
saveUpTo:"Save Up to 10%",
viewRecipts:"View All Your Reciepsts in One Place",
totalYourBill:"Total (Your Bill)",
paymentSuccess1:"Payment Success!",
paidFull:"You Paid In Full",
thankPaying:"ThankYou For Paying",
vatInclude:"VAT include",
orderPlaced:"Order Placed",
selectYouWantToPay:"Select when you want to pay",
placeOrder:"Place order",
mins:"mins",
//dine view bill
customerSupport:"In Case Of Any Issue Or Query Contact Our Customer Support",
hours:"hours",
minutes:"minutes",
seconds:"seconds",
yourOrderReady:"Your Order is Getting Ready!",
estimatedServingTime:"Estimated Serving Time is",
remainingTime:"Remaining Time",
//my reservation
rerservationRemainTime:"Your reservation remain time",
splitterDetail:"Splitter Details",
splitBillDetail:"SPLIT BILL DETAILS",

infiniteCreditCard:"FAB Infinite Credit Card",
yourCard:"Your card",
selectOrderMethod:"Select Order Method",


};
