import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FrontLayoutRoutes from "./frontend/frontRoutes";
import { Confirmation } from "../components";



  
const PayBill =  React.lazy(() => import('../container/frontend/PayBill')); 
const ItemDetails =  React.lazy(() => import('../container/frontend/itemDetails'));
const Menu =  React.lazy(() => import('../container/frontend/menu')); 
const Home =  React.lazy(() => import('../container/frontend/home'));
const Success =  React.lazy(() => import('../container/frontend/success'));
const ReserveTable =  React.lazy(() => import('../container/frontend/reserveTable'));
const PayNow =  React.lazy(() => import('../container/frontend/reserveTable/paynow'));
const AddCard =  React.lazy(() => import('../container/frontend/reserveTable/addcard'));
const CardDetail =  React.lazy(() => import('../container/frontend/splitBill/cardDetail'));
const ReservationSuccess =  React.lazy(() => import('../container/frontend/reserveTable/successReservation'));
const BillRecipt =  React.lazy(() => import('../container/frontend/dineIn/billRecipt'));
const DineViewBill =  React.lazy(() => import('../container/frontend/dineIn/dineViewBill'));
const SplitBill =  React.lazy(() => import('../container/frontend/splitBill'));
const AddSpliter =  React.lazy(() => import('../container/frontend/splitBill/addspliter'));
const SplitBillDetails =  React.lazy(() => import('../container/frontend/splitBill/splitBillDetails'));
const MySplitBills =  React.lazy(() => import('../container/frontend/splitBill/mySplitBills'));
const PaySplitBill =  React.lazy(() => import('../container/frontend/splitBill/payBill'));
const BillSuccess =  React.lazy(() => import('../container/frontend/splitBill/successPayment'));
const OrderSummary =  React.lazy(() => import('../container/frontend/foodDelivery/orderSummary'));
const History = React.lazy(() => import('../container/frontend/history'));
const MyRservation = React.lazy(() => import('../container/frontend/history/myreservation'));
const DineBillPay = React.lazy(() => import('../container/frontend/foodDelivery/dineBillPay'));
const SplitBillSelect = React.lazy(() => import('../container/frontend/splitBillSelect'));
const BillSplit = React.lazy(() => import('../container/frontend/splitBillSelect'));



function Routes() {

  return (
    <Router>
      <Switch>
        <FrontLayoutRoutes exact path="/delivery-bill-recipt"  routeName={'DeliveryBillRecipt'} render={matchprops => (<BillRecipt {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/dine-bill-recipt"  routeName={'DineBillRecipt'} render={matchprops => (<BillRecipt {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/paythebill"  routeName={'PayBill'} render={matchprops => (<PayBill {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/item-details/:id?"  routeName={'ItemDetails'} render={matchprops => (<ItemDetails {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/menu"  routeName={'Menu'} render={matchprops => (<Menu {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/"  routeName={'home'} render={matchprops => (<Home {...matchprops} />)} />
        {/* <FrontLayoutRoutes exact path="/"  routeName={'home'} render={matchprops => (<Home {...matchprops} />)} /> */}
        <FrontLayoutRoutes exact path="/payment-success"  routeName={'success'} render={matchprops => (<Success {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/reserve-table"  routeName={'reserveTable'} render={matchprops => (<ReserveTable {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/pay-now"  routeName={'payNow'} render={matchprops => (<PayNow {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/add-card"  routeName={'addCard'} render={matchprops => (<AddCard {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/card-detail"  routeName={'cardDetail'} render={matchprops => (<CardDetail {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/reservation-success"  routeName={'reservationSuccess'} render={matchprops => (<ReservationSuccess {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/split-bill"  routeName={'splitBill'} render={matchprops => (<SplitBill {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/add-spliters"  routeName={'splitAdd'} render={matchprops => (<AddSpliter {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/split-bill-details"  routeName={'splitBillDetails'} render={matchprops => (<SplitBillDetails {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/my-split-bills"  routeName={'mySplitBills'} render={matchprops => (<MySplitBills {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/pay-split-bill"  routeName={'paySplitBill'} render={matchprops => (<PaySplitBill {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/bill-success"  routeName={'billSuccess'} render={matchprops => (<BillSuccess {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/delivery-order-summary"  routeName={'DeliveryOrderSummary'} render={matchprops => (<OrderSummary {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/dine-order-summary"  routeName={'DineOrderSummary'} render={matchprops => (<OrderSummary {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/dine-view-bill"  routeName={'DineViewBill'} render={matchprops => (<DineViewBill {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/history" routeName={'history'} render={matchprops => (<History {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/my-reservation" routeName={'myRservation'} render={matchprops => (<MyRservation {...matchprops} />)}/>
        <FrontLayoutRoutes exact path="/dine-bill-pay" routeName={'dineBillPay'} render={matchprops => (<DineBillPay {...matchprops} />)}/>
        {/* <FrontLayoutRoutes exact path="/split-bill-select" routeName={'splitBillSelect'} render={matchprops => (<SplitBillSelect {...matchprops} />)}/>
        <FrontLayoutRoutes exact path="/bill-split" routeName={'billSplit'} render={matchprops => (<BillSplit {...matchprops} />)}/> */}
      </Switch>
      <Confirmation />
    </Router>
  )
    ;
}

export default Routes;